import { Gondor } from "../Gondor";

const enum Service {
  COUNT = "/click/count",
}

export interface Query {
  start?: string;
  end?: string;
  systemId?: Array<number>;
}

export function find(params?: Query) {
  return Gondor.API.get(Service.COUNT, { params });
}


import { Component, Vue } from "vue-property-decorator";
import CardChart from "@/components/CardChart.vue";
import CardBars, { BarValues } from "@/components/CardBars.vue";
import CardCount from "@/components/CardCount.vue";
import Notify from "@/utils/notifications";
import * as Click from "@/api/helpers/Click";
import * as System from "@/api/helpers/System";
import * as Dashboard from "@/api/helpers/Dashboard";
import * as Lead from "@/api/helpers/Lead";
import * as SicServices from "@/api/helpers/Sic";
import Systems from "@/models/System";
import _ from "lodash";

const SERVER_ERROR_MESSAGE = "Ocurrió un problema al conectar con el servidor.";
interface ChartValues {
  values: Array<object>;
  legends: Array<string>;
}

interface SystemValue {
  value: number;
  label: string;
}

interface ChartParams {
  end: Date | string;
  start: Date | string;
  system?: string;
}

function dateToString(date: Date | string) {
  if (typeof date === "string") {
    return date.slice(0, 10);
  }
  return date.toISOString().slice(0, 10);
}

function queryDateFormat(params?: Click.Query | Lead.CountBySystemQuery) {
  if (params) {
    if (params?.start) {
      params.start = dateToString(params.start);
    }
    if (params?.end) {
      params.end = dateToString(params.end);
    }
  }
  return params;
}

@Component({
  components: {
    CardChart,
    CardBars,
    CardCount,
  },
})
export default class Card extends Vue {
  fullscreenLoading = false;
  activeSystems: Array<Systems> = [];
  systemId = null;
  visible = false;
  loading = false;
  availableColumns: Array<Record<string, any>> = [];
  systemItems: Array<SystemValue> = [];
  dateRange = [
    new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
  ];
  pickerOptions = [];
  clickChart: ChartValues = {
    values: [],
    legends: [],
  };
  leadChart: BarValues = {
    values: [],
    legends: [],
    categories: [],
  };
  leadCompletedChart: BarValues = {
    values: [],
    legends: [],
    categories: [],
  };
  leadRepeatedChart: BarValues = {
    values: [],
    legends: [],
    categories: [],
  };
  dataType: any = {
    lead: {},
    disbursement: {},
  };

  sicChart: BarValues = {
    categories: [],
    legends: [],
    values: [],
  };

  get systemsData() {
    return this.systemItems;
  }

  get clickData() {
    return this.clickChart.values;
  }

  get clickLegends() {
    return this.clickChart.legends;
  }

  get leadFull() {
    return this.leadChart;
  }

  get leadCompleted() {
    return this.leadCompletedChart;
  }

  get leadRepeated() {
    return this.leadRepeatedChart;
  }

  async getSystems() {
    const request = await System.find({
      order: "name:asc",
      active: 1,
    });
    const data = request.data.data;
    for (let i = data.length - 1; i > -1; i--) {
      this.systemItems.push({
        label: data[i].name,
        value: data[i].id,
      });
    }
    this.activeSystems = data;
  }
  async getCliks(query?: Click.Query, callback?: () => void) {
    try {
      const request = await Click.find(queryDateFormat(query));
      const data = request.data.data;
      this.clickChart.values = [];
      this.clickChart.legends = [];
      for (let i = data.length - 1; i > -1; i--) {
        this.clickChart.values.push({
          value: data[i].count,
          name: data[i].card.name,
        });
        this.clickChart.legends.push(data[i].card.name);
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      Notify.error(SERVER_ERROR_MESSAGE);
    }
  }

  async getLeads(query?: Lead.CountBySystemQuery, callback?: () => void) {
    try {
      if (typeof query === "undefined") {
        query = {};
      }
      query.dateFormat = "DD/MM/YYYY";
      const request = await Lead.countBySystem(queryDateFormat(query));

      const data = request.data.data;
      this.leadChart.values = [];
      this.leadChart.legends = [];
      this.leadChart.categories = [];

      const keys = Object.keys(data);
      if (data.dates) {
        this.leadChart.categories = data.dates;
        const index = keys.indexOf("dates");
        keys.splice(index, 1);
      }

      for (let i = keys.length - 1; i > -1; i--) {
        const key = keys[i];
        this.leadChart.values.push({
          stack: "1",
          values: data[key].data,
          name: data[key].name,
        });
        this.leadChart.legends.push(data[key].name);
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      Notify.error(SERVER_ERROR_MESSAGE);
    }
  }

  async getLeadsCompleted(
    query?: Lead.CountBySystemQuery,
    callback?: () => void
  ) {
    try {
      if (typeof query === "undefined") {
        query = {};
      }
      query.completed = true;
      query.dateFormat = "DD/MM/YYYY";

      const request = await Lead.countBySystem(queryDateFormat(query));

      const data = request.data.data;
      this.leadCompletedChart.values = [];
      this.leadCompletedChart.legends = [];
      this.leadCompletedChart.categories = [];

      const keys = Object.keys(data);
      if (data.dates) {
        this.leadCompletedChart.categories = data.dates;
        const index = keys.indexOf("dates");
        keys.splice(index, 1);
      }

      for (let i = keys.length - 1; i > -1; i--) {
        const key = keys[i];
        this.leadCompletedChart.values.push({
          stack: "1",
          values: data[key].data,
          name: data[key].name,
        });
        this.leadCompletedChart.legends.push(data[key].name);
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      Notify.error(SERVER_ERROR_MESSAGE);
    }
  }

  _getLeadsRepeated(
    data: {
      [key: string]: any;
    },
    name: string,
    stack: string,
    vIndex: number
  ) {
    const keys = Object.keys(data);
    const index = keys.indexOf("dates");
    keys.splice(index, 1);
    const sumArrs = (a: Array<number>, b: Array<number>) =>
      a.map(function(v, i) {
        return v + b[i];
      });
    for (let i = keys.length - 1; i > -1; i--) {
      const key = keys[i];
      if (this.leadRepeatedChart.values.length === vIndex) {
        this.leadRepeatedChart.values.push({
          stack: stack,
          values: data[key].data,
          name: name,
        });
      } else {
        this.leadRepeatedChart.values[vIndex].values = sumArrs(
          this.leadRepeatedChart.values[vIndex].values,
          data[key].data
        );
      }
      this.leadRepeatedChart.legends.push(name);
    }
  }

  async getLeadsRepeated(
    query?: Lead.CountBySystemQuery,
    callback?: () => void
  ) {
    try {
      if (typeof query === "undefined") {
        query = {};
      }
      query.dateFormat = "DD/MM/YYYY";

      query.identicalPhones = {
        gte: 1,
      };
      const requestPhones = await Lead.countBySystem(queryDateFormat(query));

      delete query.identicalPhones;
      query.identicalEmails = {
        gte: 1,
      };
      const requestEmails = await Lead.countBySystem(queryDateFormat(query));

      const dataPhones = requestPhones.data.data;
      const dataEmails = requestEmails.data.data;

      this.leadRepeatedChart.values = [];
      this.leadRepeatedChart.legends = [];
      this.leadRepeatedChart.categories = [];

      if (dataPhones.dates) {
        this.leadRepeatedChart.categories = dataPhones.dates;
        this._getLeadsRepeated(dataPhones, "Correo electrónico", "1", 0);
      }
      if (dataEmails.dates) {
        if (this.leadRepeatedChart.categories.length > 0) {
          this.leadRepeatedChart.categories = dataEmails.dates;
        }
        this.leadRepeatedChart.categories = dataPhones.dates;
        this._getLeadsRepeated(dataEmails, "Teléfono", "2", 1);
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
      Notify.error(SERVER_ERROR_MESSAGE);
    }
  }

  resetSicChart() {
    this.sicChart = {
      categories: [],
      legends: [],
      values: [],
    };
  }

  async findSic(params: ChartParams) {
    const { end, start, system } = params;
    this.resetSicChart();
    try {
      const response = await SicServices.count({
        end,
        start,
        systemId: system,
      });
      const { data } = response.data;
      this.sicChart.categories = data.labels;
      data.datasets.forEach(value => {
        this.sicChart.legends.push(value.label);
        this.sicChart.values.push({
          name: value.label,
          stack: "1",
          values: value.data,
        });
      });
    } catch (error) {
      Notify.gebServerError(error);
    }
  }

  created() {
    const index = "1";
    const title = "Dashboard";
    const start = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
    const end = new Date(new Date().setHours(23, 59, 59, 999)).toISOString();
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.getSystems()
      .then(() => {
        Promise.all([
          this.getLeads({ start, end }),
          this.getLeadsCompleted({ start, end }),
          this.getCliks({ start, end }),
          this.findSic({ start, end }),
        ]);
      })
      .catch(err => {
        console.error(err);
        Notify.error(SERVER_ERROR_MESSAGE);
      });
  }

  async getCount(query?: any, callback?: () => void) {
    try {
      if (typeof query === "undefined") {
        query = {};
      }
      const request = await Dashboard.find(query.type, query);
      const unordered = _.groupBy(request.data.data.Leads, "utm_medium");
      this.dataType[query.type] = {
        total: request.data.data.Total,
        channels: Object.keys(unordered)
          .sort()
          .reduce((obj: any, key: string) => {
            obj[key] = unordered[key];
            return obj;
          }, {}),
      };
      if (callback) callback();
    } catch (err) {
      this.dataType[query.type] = { error: err.response.data.message };
      if (callback) callback();
      Notify.warning(err.response.data.message);
    }
  }
}

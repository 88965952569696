import { Gondor, Response } from "../Gondor";

export interface Sic {
  id: number;
  number: string;
  requestNumber: string;
  requestType: string;
  requestDate: null | Date;
  approvalDate: null | Date;
  requestAuthorization: boolean;
  authenticationAuthorization: boolean;
  nipAuthorization: boolean;
  score: number;
  createdAt: Date;
  updatedAt: Date;
  leadId: null | number;
}

interface Dataset {
  label: string;
  data: number[];
}

interface Count {
  labels: string[];
  datasets: Dataset[];
}

export interface Params {
  start: Date | string;
  end: Date | string;
  systemId?: string;
}

const ROUTES = {
  count: "/sic/count",
};

export const count = (params: Params) => {
  return Gondor.API.get<Response<Count>>(ROUTES.count, { params });
};

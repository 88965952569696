import { Gondor } from "../Gondor";

const ACTIONS = {
  COUNT: "/solventa/webhook/{type}",
};

export interface Query {
  start?: string;
  end?: string;
  systemId?: Array<number>;
}

export function find(type: string, params?: object) {
  return Gondor.API.post(ACTIONS.COUNT.replace("{type}", type), params);
}
